@import "src/app/styles/variables";

.Directories {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;

  &__content {
    flex-grow: 1;
    padding: 10px 4px;
    overflow-y: auto;
  }

  &__itemHeader {
    width: 100%;
    display: flex;
    align-items: center;
    transition: background-color .3s ease, color .3s ease;

    &_selected {
      background-color: $selected-list;
    }
  }

  &__itemIcon {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__itemText {
    flex-grow: 1;
  }

  &__list {
    height: 100%;

    :global .ant-tree {
      .ant-tree-node-content-wrapper {
        padding: 0;
        line-height: 22px;
      }

      .ant-tree-switcher {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-tree-switcher-icon {
          font-size: 14px;

          svg {
            transform: rotate(0);
          }
        }

        &.ant-tree-switcher-noop {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='folder' width='14px' height='14px' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32zM840 768H184V256h188.5l119.6 114.4H840V768z'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          opacity: .5;
        }
      }

      .ant-tree-indent-unit {
        width: 20px;
      }

      .ant-tree-draggable-icon {
        display: none;
      }
    }
  }
}
