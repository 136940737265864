@import "../../../../../src/app/styles/variables";

.TableResponsive {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &_title {
    width: 100%;
    padding: 8px;

    button {
      margin: 0;
    }
  }

  :global(> .ant-table-wrapper) {
    border-top: 1px solid $grey-border;
  }

  :global {
    .ant-table-wrapper {
      .ant-table {
        border-bottom: 1px solid $grey-border;
      }
    }
  }
}
