.LuaScript {
  padding-top: 10px;

  &_top {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 20px 10px;

    input[type='text'] {
      width: 90px;
    }
  }

  &_content {
    display: flex;
  }

  &_contentItem {
    width: 50%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_tableWrapper {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    overflow-x: auto;
  }

  &_table {
    height: 100%;
  }

  :global {
    .ant-form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    .ant-form-item,
    .ant-row,
    .ant-col,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
      height: 100%;
      resize: none;
    }

    .cm-theme {
      width: 100%;
      height: 100%;
      overflow: auto;

      .cm-editor {
        height: 100%;
      }
    }
  }
}
