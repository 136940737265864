@mixin bd-list {
  @each $pos in ('', 'top', 'bottom', 'left', 'right') {
    @if ($pos != '') {
      .bd-#{$pos} {
        border-#{$pos}: 1px solid $grey-border;
      }
    } @else {
      .bd {
        border: 1px solid $grey-border;
      }
    }
  }
}
