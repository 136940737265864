.ModuleLayout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 4px 20px;

    button {
      margin-right: 10px;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
}
