.Icmp {
  height: 100%;

  &_header {
    padding: 10px 20px;
  }

  :global {
    .ant-form {
      &-item {
        margin-bottom: 0 !important;
      }
    }
  }
}
