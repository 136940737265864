.ChannelContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &_content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
}
