body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

@include bd-list;

.bg-white {
  background-color: #fff;
}

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.content-wrapper {
  padding: 10px 20px;
}

.working-content {
  height: 100%;
  display: flex;
  align-items: self-start;
  flex-grow: 1;
  overflow: hidden;
}

.channels, .working-area, .plugins {
  height: 100%;
}

.working-area {
  width: 100%;
  overflow: hidden;
}

.input-item {
  display: flex;
  align-items: center;

  input {
    width: auto;
  }
}

.module-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.module-table {
  min-height: 320px;
  flex-grow: 1;
  overflow: hidden;
}

.input-disabled {
  pointer-events: none;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.modal-with-code {
  height: 500px;
  display: flex;

  &__item {
    width: 50%;

    &__table {
      overflow-x: auto;
    }
  }

  :global {
    .cm-theme {
      width: 100%;
      height: 100%;
      overflow: auto;

      .cm-editor {
        height: 100%;
      }
    }
  }
}
