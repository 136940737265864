.EventLog {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;

  &_table {
    flex-grow: 1;
    overflow: hidden;
  }

  :global .ant-table-body {
    height: 243px;
  }
}
