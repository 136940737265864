.Plugins {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;

  &_content {
    flex-grow: 1;
    padding: 10px 4px;
    overflow-y: auto;
  }
}
