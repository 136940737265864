.react-resizable {
  width: 100% !important;
  flex-shrink: 0;

  &-handle {
    width: 100%;
    background: none;
    margin-left: 0;
    left: 0;
    transform: rotate(0);
  }
}
