.About {
  :global {
    .ant-list-grid {
      .ant-col > .ant-list-item {
        margin-block-end: 0;
        padding: 4px 8px;
      }
    }
  }
}
