.Objects {
  min-height: 300px;
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  &__folders {
    width: 200px;
    flex-shrink: 0;
  }
}
