.License {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &_content {
    height: max-content;
    min-height: 100%;
    display: flex;
  }

  &_col {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 20px 10px;
  }
}
