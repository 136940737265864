.Header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &__title {
    flex-grow: 1;
    padding: 8px 0;
  }

  &__menu {
    flex-grow: 1;
    padding: 10px 20px;
  }

  img {
    margin-right: 12px;
  }

  :global {
    h1.ant-typography {
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 0;
    }
  }
}
