.tabs {
    height: 40px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 10px 20px 0;
}

.tab-current {
    border-bottom: 2px solid white;
}

.tab:hover {
    background: blue;
    color: white;
}
