@import "../../../app/styles/variables";

.BlockHeader {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 4px;
  background-color: $grey;

  &_actions {
    button {
      margin-right: 4px;
    }
  }

  button {
    z-index: 10;
  }
}

:global(.ant-table-expanded-row) {
  .BlockHeader {
    border: 1px solid $grey-border;
    border-bottom: none;
  }
}
