.BindingChannels {
  height: 100%;
  display: flex;

  &_directories {
    width: 200px;
    flex-shrink: 0;
  }

  &_table {
    flex-grow: 1;
  }

  &_addedTable {
    width: 250px;
    flex-shrink: 0;

    :global {
      .ant-table-body {
        .ant-table-row {
          td.ant-table-cell:not(.ant-table-selection-column) {
            display: flex;
            justify-content: space-between;

            button {
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}
