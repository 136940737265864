/*
* Tabs
*/
.ant-tabs {
  width: 100%;

  .ant-tabs-content-holder {
    display: none !important;
  }

  .ant-tabs-nav {
    margin: 0 !important;

    .ant-tabs-nav-more {
      padding: 4px 16px;
    }

    &::before {
      border: none !important;
    }
  }

  .ant-tabs-tab {
    padding: 3px 16px !important;
  }
}

/*
* Table
*/
.ant-table,
.ant-table-container,
.ant-table-header,
.ant-table table,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child,
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
  border-radius: 0 !important;
}

.ant-table-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .ant-spin-container,
  .ant-table,
  .ant-table-container,
  .ant-table-content,
  table {
    height: 100%;
  }

  .ant-table {
    overflow: hidden;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
  }

  .ant-table-thead > tr > th {
    &::before {
      display: none !important;
    }

    .title-with-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      button {
        flex-shrink: 0;
        padding: 0;
        margin: 0;
      }
    }
  }

  .ant-table-container {
    display: flex;
    flex-direction: column;

    .ant-table-header {
      flex-shrink: 0;
    }

    .ant-table-cell {
      border-right: 1px solid $grey-border;
    }

    .ant-table-body {
      height: 100%;
      overflow-y: scroll;

      tbody {
        vertical-align: top;

        .ant-table-row,
        .ant-table-expanded-row {
          height: 40px;
        }

        .ant-table-expanded-row {
          & > .ant-table-cell {
            padding-bottom: 0;
          }
        }

        .ant-table-measure-row {
          visibility: collapse;
        }
      }
    }
  }

  .ant-table-pagination {
    &.ant-pagination {
      margin: 16px;
    }
  }

  .editable-cell-value-wrap-empty {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .ant-table {
    .ant-table-tbody {
      .ant-table-wrapper:only-child {
        .ant-table.ant-table-small {
          margin-block: 0;
          margin-inline: 39px 0;
          border: 1px solid $grey-border;
          border-bottom: none;
          border-right: none;
        }
      }
    }
  }
}

/*
* Form
*/
.ant-form {
  .ant-form-item {
    margin-bottom: 16px;

    &.textarea {
      .ant-form-item-row {
        flex-flow: column nowrap;
        align-items: flex-start;
      }
    }

    &.checkbox {
      .ant-form-item-label {
        visibility: hidden;
      }
    }
  }

  .ant-input-textarea-show-count {
    margin-bottom: 16px;
  }

  &.module {
    .ant-form-item-control-input + div {
      position: absolute;
    }

    .ant-form-item-margin-offset {
      margin-bottom: 0 !important;
    }
  }
}

/*
* Spin
*/
.ant-spin-nested-loading {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & > div > .ant-spin {
    max-height: 100%;
  }
}
